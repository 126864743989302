















































import i18n from '@/i18n';
import { Component, Vue } from 'vue-property-decorator';
import InputMandatoryLabel from '@/ui/components/molecules/inputs/InputMandatoryLabel.vue';
import InputChannelAndZoneLabel from '@/ui/components/molecules/inputs/InputChannelAndZoneLabel.vue';
import { getEmptyCampaignTag } from '@/ui/services/tags/tagService';
import type { LanguageObject } from '@/core/campaign/models/LanguageObject';
import { findTagsUseCase } from '@/core/tags/usecases/findTagsUseCase';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import type { Tag } from '@/core/tags/models/Tag';
import { TagType } from '@/core/tags/enums/TagType';
import { CampaignStoreMutations } from '@/ui/stores/CampaignStore';
import { AdminStoreMutations } from '@/ui/stores/AdminStore';

@Component({
  name: 'AdminMandatoryLabelsTab',
  components: {
    InputMandatoryLabel,
    InputChannelAndZoneLabel,
  },
})
export default class AdminMandatoryLabelsTab extends Vue {
  private tagType = TagType;

  private locale: keyof LanguageObject = i18n.i18next.language;

  async created() {
    await this.fetchTags(TagType.CHANNEL);
    await this.fetchTags(TagType.ZONE);
    await this.fetchTags(TagType.LANGUAGE);
    await this.fetchTags(TagType.FORMAT);
  }

  get tags() {
    return this.$store.state.adminStore.tags;
  }

  get currentUser(): CurrentUser | undefined {
    return this.$store.state.userStore.currentUser;
  }

  async fetchTags(type: TagType) {
    this.resetZonesForCampaigns(type);
    if (this.currentUser) {
      this.tags[type] = await findTagsUseCase({ type }, this.currentUser);
    }
  }

  resetZonesForCampaigns(type: TagType) {
    if (type === TagType.ZONE) {
      this.$store.commit(CampaignStoreMutations.SET_ALL_AVAILABLE_CAMPAIGN_ZONES, undefined);
      this.$store.commit(AdminStoreMutations.SET_ALL_ZONES, undefined);
    }
  }

  openChannelInputfield() {
    const emptyChannelTag = getEmptyCampaignTag(TagType.CHANNEL);
    this.tags.channel.push(emptyChannelTag as Tag);
  }
}
