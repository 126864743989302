


































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { getEmptyLanguageObject } from '@/ui/services/tags/tagService';
import i18n from '@/i18n';
import { cloneDeep as _cloneDeep } from 'lodash';
import getLogger from '@/shared/logger/logger';
import type { Tag } from '@/core/tags/models/Tag';

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const LOG = getLogger('WithMenuChannelName');

@Component({
  name: 'WithMenuChannelName',
})
export default class WithMenuChannelName extends Vue {
  @Prop() tag!: Tag;
  @Prop({ default: true }) showDelete?: boolean;
  private showMenu = false;
  private modalEditLabel = {
    valid: false,
    fields: getEmptyLanguageObject(),
    rules: {
      'de-CH': [this.requiredRule('de-CH')],
      'fr-CH': [this.requiredRule('fr-CH')],
      'it-CH': [this.requiredRule('it-CH')],
      'en-CH': [this.requiredRule('en-CH')],
    },
  };

  mounted() {
    this.modalEditLabel.fields = _cloneDeep(this.tag.value);
  }

  requiredRule(language: string): (value: unknown) => boolean | string {
    if (language === i18n.i18next.language) {
      return (value) => !!value || value === 0 || this.$t('shared.common.error-required-field');
    }
    return () => true;
  }

  discardUnsavedChanges() {
    this.modalEditLabel.fields = _cloneDeep(this.tag.value);
  }

  handleAbortClicked() {
    this.modalEditLabel.fields = _cloneDeep(this.tag.value);
    this.showMenu = false;
  }

  handleSaveClicked() {
    this.$emit('saveTranslations', this.modalEditLabel.fields, this.tag.id);
    this.showMenu = false;
  }

  emitRemovedClick() {
    this.$emit('removeClick');
    this.showMenu = false;
  }
}
