





















































import { Component, Vue, Prop } from 'vue-property-decorator';
import WithMenuLabelRow from '@/ui/components/atoms/groupingItems/WithMenuLabelRow.vue';
import type { LanguageObject } from '@/core/campaign/models/LanguageObject';
import getLogger from '@/shared/logger/logger';
import ModalWarning from '@/ui/components/molecules/modals/ModalWarning.vue';
import { isEmptyOrDuplicate, getEmptyCampaignTag } from '@/ui/services/tags/tagService';
import i18n from '@/i18n';
import { TagType } from '@/core/tags/enums/TagType';
import type { Tag } from '@/core/tags/models/Tag';
import createTagUseCase from '@/core/tags/usecases/createTagUseCase';
import type { User } from '@/core/admin/models/User';
import { updateTagUseCase } from '@/core/tags/usecases/updateTagUseCase';
import { UpdateTagDTO } from '@/core/tags/DTO/UpdateTagDTO';
import { CreateTagDTO } from '@/core/tags/DTO/CreateTagDTO';

const LOG = getLogger('InputMandatoryLabel');

enum ActionTypes {
  REMOVE = 'remove',
  TRANSLATE = 'translate',
}

@Component({
  name: 'InputMandatoryLabel',
  components: {
    WithMenuLabelRow,
    ModalWarning,
  },
})
export default class InputMandatoryLabel extends Vue {
  @Prop() inputTitle!: string;
  @Prop() tagType!: TagType;
  @Prop() predefinedTags!: Tag[];
  @Prop() currentUser!: User;
  private textInput = '';
  private locale: keyof LanguageObject = i18n.i18next.language;
  private showWarningModal = false;
  private actionType: ActionTypes = ActionTypes.REMOVE;
  private toBeUpdatedTag: UpdateTagDTO = getEmptyCampaignTag(this.tagType);
  private toBeUpdatedTagId = '';

  async saveTagTranslations() {
    await updateTagUseCase(this.toBeUpdatedTagId, this.toBeUpdatedTag, this.currentUser);
    this.$emit('updated', this.tagType);
  }

  async addTag() {
    const newLabel: CreateTagDTO = getEmptyCampaignTag(this.tagType);
    newLabel.value[this.locale] = this.textInput;

    if (!isEmptyOrDuplicate(newLabel, this.predefinedTags, this.locale)) {
      this.textInput = '';
      await createTagUseCase(newLabel, this.currentUser);
      this.$emit('updated', this.tagType);
    } else {
      LOG.warn('Error, duplicate or empty label');
    }
  }

  async updateIsActiveValue(tag: Tag) {
    await updateTagUseCase(tag.id, { ...tag }, this.currentUser);
  }

  async updateIsDefaultValue(incomingTag: Tag) {
    for (const tag of this.predefinedTags) {
      tag.isDefault = tag.id === incomingTag.id;
      await updateTagUseCase(tag.id, { ...tag }, this.currentUser);
    }
  }

  initialDefaultTag() {
    return this.predefinedTags ? this.predefinedTags.find((tag: Tag) => tag.isDefault) : [];
  }

  handleModalSave() {
    if (this.actionType == ActionTypes.TRANSLATE) {
      this.saveTagTranslations();
    } else {
      this.deleteTag();
    }
    this.showWarningModal = false;
  }

  handleModalAbort() {
    this.showWarningModal = false;
  }

  hasItems() {
    return this.predefinedTags?.length > 0;
  }

  showTranslationsWarning(value: LanguageObject, id: string) {
    this.toBeUpdatedTag = { ...this.toBeUpdatedTag, value: value };
    this.toBeUpdatedTagId = id;
    this.showWarningModal = true;
    this.actionType = ActionTypes.TRANSLATE;
  }

  showDeleteWarning(id: string) {
    this.toBeUpdatedTagId = id;
    this.showWarningModal = true;
    this.actionType = ActionTypes.REMOVE;
  }

  deleteTag() {
    // Todo
  }
}
