




















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import WithMenuLabelRow from '@/ui/components/atoms/groupingItems/WithMenuLabelRow.vue';
import type { LanguageObject } from '@/core/campaign/models/LanguageObject';
import ModalWarning from '@/ui/components/molecules/modals/ModalWarning.vue';
import WithMenuChannelName from '@/ui/components/atoms/groupingItems/WithMenuChannelName.vue';
import {
  getEmptyLanguageObject,
  isEmptyOrDuplicate,
  getEmptyZoneTag,
  getEmptyCampaignTag,
} from '@/ui/services/tags/tagService';
import getLogger from '@/shared/logger/logger';
import i18n from '@/i18n';
import { TagType } from '@/core/tags/enums/TagType';
import type { Tag } from '@/core/tags/models/Tag';
import createTagUseCase from '@/core/tags/usecases/createTagUseCase';
import type { User } from '@/core/admin/models/User';
import { updateTagUseCase } from '@/core/tags/usecases/updateTagUseCase';
import { CreateTagDTO } from '@/core/tags/DTO/CreateTagDTO';

const LOG = getLogger('InputChannelAndZoneLabel');

enum ActionTypes {
  REMOVE_ZONE = 'remove_zone',
  REMOVE_CHANNEL = 'remove_channel',
  TRANSLATE_ZONE = 'translate_zone',
  TRANSLATE_CHANNEL = 'translate_channel',
}

@Component({
  name: 'InputChannelAndZoneLabel',
  components: {
    WithMenuLabelRow,
    WithMenuChannelName,
    ModalWarning,
  },
})
export default class InputChannelAndZoneLabel extends Vue {
  @Prop({ default: null }) channelIndex!: number;
  @Prop({ default: null }) channel!: Tag;
  @Prop({ default: null }) zones!: Tag[];
  @Prop({ default: null }) currentUser!: User;
  showWarningModal = false;
  textInput = '';
  private channelInput = '';
  private readonly locale: keyof LanguageObject = i18n.i18next.language;
  private newLanguageObject: LanguageObject = getEmptyLanguageObject();
  private newIndex = 0;
  private actionType: ActionTypes = ActionTypes.REMOVE_CHANNEL;
  private tagType = TagType;
  private toBeUpdatedZone: Tag = getEmptyZoneTag(this.channel) as Tag;
  private toBeUpdatedChannel: Tag = getEmptyCampaignTag(this.tagType.CHANNEL) as Tag;
  private toBeUpdatedTagId = '';

  created() {
    this.showWarningModal = false;
    this.textInput = '';
  }

  get zonesByChannel() {
    return this.zones
      ? this.zones.filter((zone) => {
          if (zone && zone.group) {
            return zone.group.id === this.channel.id;
          } else {
            return getEmptyZoneTag(this.channel);
          }
        })
      : [];
  }

  async addChannelTag() {
    if (this.channelInput && this.channelInput?.length > 0) {
      const newTag: CreateTagDTO = getEmptyCampaignTag(TagType.CHANNEL);
      newTag.value[this.locale] = this.channelInput;
      this.channelInput = '';
      await createTagUseCase(newTag, this.currentUser);
      this.$emit('updated', this.tagType.CHANNEL);
    }
  }

  async addZoneTag() {
    const newTag: CreateTagDTO = getEmptyZoneTag(this.channel);
    if (newTag.value) {
      newTag.value[this.locale] = this.textInput;
    }
    if (!isEmptyOrDuplicate(newTag, this.zones, this.locale)) {
      this.textInput = '';
      await createTagUseCase(newTag, this.currentUser);
      this.$emit('updated', this.tagType.ZONE);
    } else {
      LOG.warn('TODO: Error, duplicate or empty label');
    }
  }

  async updateIsActiveValue(tag: Tag) {
    await updateTagUseCase(tag.id, { ...tag }, this.currentUser);
  }

  async updateIsDefaultValue(tag: Tag) {
    await updateTagUseCase(tag.id, { ...tag }, this.currentUser);
  }

  async updateMediaValue(value: number, tagId: string) {
    await updateTagUseCase(tagId, { estimatedPlayValue: value }, this.currentUser);
  }

  async saveZoneTranslations() {
    await updateTagUseCase(this.toBeUpdatedTagId, this.toBeUpdatedZone, this.currentUser);
    this.$emit('updated', this.tagType.ZONE);
  }

  async saveChannelTranslations() {
    await updateTagUseCase(this.toBeUpdatedTagId, this.toBeUpdatedChannel, this.currentUser);
    this.$emit('updated', this.tagType.CHANNEL);
  }

  hasZoneItems() {
    return this.zonesByChannel?.length > 0;
  }

  hasChannelName() {
    return this.$tagValue(this.channel)?.length > 0;
  }

  handleModalSave() {
    switch (this.actionType) {
      case ActionTypes.TRANSLATE_ZONE:
        this.saveZoneTranslations();
        break;
      case ActionTypes.TRANSLATE_CHANNEL:
        this.saveChannelTranslations();
        break;
    }
    this.showWarningModal = false;
  }

  handleModalAbort() {
    this.showWarningModal = false;
  }

  showZoneTranslationsWarning(value: LanguageObject, id: string) {
    this.toBeUpdatedZone = { ...this.toBeUpdatedZone, value };
    this.toBeUpdatedTagId = id;
    this.showWarningModal = true;
    this.actionType = ActionTypes.TRANSLATE_ZONE;
  }

  showChannelTranslationsWarning(value: LanguageObject, id: string) {
    this.toBeUpdatedChannel = { ...this.toBeUpdatedChannel, value };
    this.toBeUpdatedTagId = id;
    this.showWarningModal = true;
    this.actionType = ActionTypes.TRANSLATE_CHANNEL;
  }

  showDeleteZoneWarning(index: number) {
    this.newIndex = index;
    this.showWarningModal = true;
    this.actionType = ActionTypes.REMOVE_ZONE;
  }

  showDeleteChannelWarning() {
    this.showWarningModal = true;
    this.actionType = ActionTypes.REMOVE_CHANNEL;
  }
}
