





































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import i18n from '@/i18n';
import { cloneDeep as _cloneDeep } from 'lodash';
import { getEmptyLanguageObject } from '@/ui/services/tags/tagService';
import getLogger from '@/shared/logger/logger';
import type { Tag } from '@/core/tags/models/Tag';
import { Languages } from '@/core/admin/constants/Languages.const';

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const LOG = getLogger('WithMenuLabelRow');

@Component({
  name: 'WithMenuLabelRow',
})
export default class WithMenuLabelRow extends Vue {
  @Prop() tag!: Tag;
  @Prop({ default: false }) isZoneItem?: boolean;
  @Prop({ default: true }) showDelete?: boolean;
  private showMenu = false;
  private modalEditLabel = {
    valid: false,
    fields: getEmptyLanguageObject(),
    rules: {
      [Languages.DE]: [this.requiredRule(Languages.DE)],
      [Languages.FR]: [this.requiredRule(Languages.FR)],
      [Languages.IT]: [this.requiredRule(Languages.IT)],
      [Languages.EN]: [this.requiredRule(Languages.EN)],
    },
  };
  private mediaValue = 0.1;

  mounted() {
    this.modalEditLabel.fields = _cloneDeep(this.tag.value);
    this.mediaValue = this.tag.estimatedPlayValue || 0.1;
  }

  requiredRule(language: string): (value: unknown) => boolean | string {
    if (language === i18n.i18next.language) {
      return (value) => !!value || value === 0 || this.$t('shared.common.error-required-field');
    }
    return () => true;
  }

  discardUnsavedChanges() {
    this.modalEditLabel.fields = _cloneDeep(this.tag.value);
  }

  handleAbortClicked() {
    this.modalEditLabel.fields = _cloneDeep(this.tag.value);
    this.showMenu = false;
  }

  handleSaveClicked() {
    this.$emit('saveTranslations', this.modalEditLabel.fields, this.tag.id);
    this.showMenu = false;
  }

  emitRemovedClick() {
    this.$emit('removeClick', this.tag.id);
    this.showMenu = false;
  }

  updateMediaValue() {
    this.$emit('updateMediaValue', this.mediaValue, this.tag.id);
  }
}
